@font-face {
  font-family: Flaticon;
  src: url("Flaticon.f4897f54.eot");
  src: url("Flaticon.f4897f54.eot#iefix") format("embedded-opentype"), url("Flaticon.0d8d5508.woff") format("woff"), url("Flaticon.638839e8.ttf") format("truetype"), url("Flaticon.406d2ce3.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: Flaticon;
    src: url("Flaticon.406d2ce3.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before, [class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
  font-size: 36px;
  font-style: normal;
}

.wt-icon-box-xl [class^="flaticon-"]:before, .wt-icon-box-xl [class*=" flaticon-"]:before, .wt-icon-box-xl [class^="flaticon-"]:after, .wt-icon-box-xl [class*=" flaticon-"]:after {
  font-size: 96px;
}

.wt-icon-box-lg [class^="flaticon-"]:before, .wt-icon-box-lg [class*=" flaticon-"]:before, .wt-icon-box-lg [class^="flaticon-"]:after, .wt-icon-box-lg [class*=" flaticon-"]:after {
  font-size: 72px;
}

.wt-icon-box-md [class^="flaticon-"]:before, .wt-icon-box-md [class*=" flaticon-"]:before, .wt-icon-box-md [class^="flaticon-"]:after, .wt-icon-box-md [class*=" flaticon-"]:after {
  font-size: 48px;
}

.wt-icon-box-sm [class^="flaticon-"]:before, .wt-icon-box-sm [class*=" flaticon-"]:before, .wt-icon-box-sm [class^="flaticon-"]:after, .wt-icon-box-sm [class*=" flaticon-"]:after {
  font-size: 36px;
}

.wt-icon-box-xs [class^="flaticon-"]:before, .wt-icon-box-xs [class*=" flaticon-"]:before, .wt-icon-box-xs [class^="flaticon-"]:after, .wt-icon-box-xs [class*=" flaticon-"]:after {
  font-size: 24px;
}

.flaticon-envelope:before {
  content: "";
}

.flaticon-smartphone:before {
  content: "";
}

.flaticon-placeholder:before {
  content: "";
}

.flaticon-spa:before {
  content: "";
}

.flaticon-lipstick:before {
  content: "";
}

.flaticon-hair-dye:before {
  content: "";
}

.flaticon-combs:before {
  content: "";
}

.flaticon-cream:before {
  content: "";
}

.flaticon-eye-shadow:before {
  content: "";
}

.flaticon-eyelashes:before {
  content: "";
}

.flaticon-eye:before {
  content: "";
}

.flaticon-hair-dryer:before {
  content: "";
}

.flaticon-deodorant:before {
  content: "";
}

.flaticon-curling:before {
  content: "";
}

.flaticon-cosmetics:before {
  content: "";
}

.flaticon-brushes:before {
  content: "";
}

.flaticon-bronzer:before {
  content: "";
}

.flaticon-blush:before {
  content: "";
}

.flaticon-bath-salt-bomb:before {
  content: "";
}

.flaticon-happy:before {
  content: "";
}

.flaticon-lotion:before {
  content: "";
}

.flaticon-mascara:before {
  content: "";
}

.flaticon-mirror:before {
  content: "";
}

.flaticon-people:before {
  content: "";
}

.flaticon-team:before {
  content: "";
}

.flaticon-trophy:before {
  content: "";
}

.flaticon-spray-bottle:before {
  content: "";
}

.flaticon-wipes:before {
  content: "";
}

.flaticon-hair-straightener:before {
  content: "";
}

.flaticon-soap:before {
  content: "";
}

.flaticon-gel:before {
  content: "";
}

.flaticon-shaving:before {
  content: "";
}

.flaticon-serum:before {
  content: "";
}

.flaticon-scissors:before {
  content: "";
}

.flaticon-powder:before {
  content: "";
}

.flaticon-potions:before {
  content: "";
}

.flaticon-perfume:before {
  content: "";
}

.flaticon-nail-polish:before {
  content: "";
}

.flaticon-nail:before {
  content: "";
}

.flaticon-female-hairs:before {
  content: "";
}

@font-face {
  font-family: icomoon;
  src: url("icomoon.1f3ae104.eot");
  src: url("icomoon.1f3ae104.eot#iefix") format("embedded-opentype"), url("icomoon.57d2a4c6.ttf") format("truetype"), url("icomoon.7a00e203.woff") format("woff"), url("icomoon.32a7dbe3.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="iconmoon-"], [class*=" iconmoon-"] {
  speak: none;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: icomoon !important;
}

.iconmoon-hours:before {
  content: "";
}

.iconmoon-hours-1:before {
  content: "";
}

.iconmoon-buildings:before {
  content: "";
}

.iconmoon-clock:before {
  content: "";
}

.iconmoon-clock-1:before {
  content: "";
}

.iconmoon-email:before {
  content: "";
}

.iconmoon-envelope:before {
  content: "";
}

.iconmoon-envelope-1:before {
  content: "";
}

.iconmoon-fax:before {
  content: "";
}

.iconmoon-house:before {
  content: "";
}

.iconmoon-placeholder:before {
  content: "";
}

.iconmoon-smartphone:before {
  content: "";
}

.iconmoon-smartphone-1:before {
  content: "";
}

.iconmoon-time:before {
  content: "";
}

.iconmoon-time-1:before {
  content: "";
}

.iconmoon-time-2:before {
  content: "";
}

.iconmoon-travel:before {
  content: "";
}

.icon-xld i, .icon-xld [class*="iconmoon-"]:before {
  font-size: 100px;
}

.icon-xl i, .icon-xl [class*="iconmoon-"]:before {
  font-size: 80px;
}

.icon-lg i, .icon-lg [class*="iconmoon-"]:before {
  font-size: 60px;
}

.icon-md i, .icon-md [class*="iconmoon-"]:before {
  font-size: 45px;
}

.icon-sm i, .icon-sm [class*="iconmoon-"]:before {
  font-size: 30px;
}

.icon-xs i, .icon-xs [class*="iconmoon-"]:before {
  font-size: 20px;
}

/*# sourceMappingURL=index.b1a41dc8.css.map */
